/// <reference path="../../../declarations.d.ts" />

import GlyphLink, { ViewPort } from "components/GlyphLink";
import React from "react";
import { Link } from "react-router-dom";
import Instagram from "static/img/insta.svg";
import Vimeo from "static/img/vimeo.svg";
import useMediaQuery from "../hooks/UseMediaQuery";

const SOCIALS: [React.VFC<React.SVGProps<SVGSVGElement>>, string, string, ViewPort][] = [
	[Instagram, "Instagram", "https://instagram.com/maya_armon", 672],
	[Vimeo, "Vimeo", "https://vimeo.com/mayaarmon", { x: 0, y: 0, w: 138, h: 115.56 }],
];

export default () => {
	const isDesktop = useMediaQuery(1024);
	const socials = SOCIALS.map((social) => (
		<GlyphLink
			className={!isDesktop && "verticalNavItem"}
			glyph={social[0]}
			text={!isDesktop && social[1]}
			href={social[2]}
			viewPort={social[3]}
		/>
	));

	let heading;
	if (isDesktop) {
		heading = (
			<>
				<h1 className="inlineHeading">MAYA ARMON</h1>
				<div className="inlineSocials">{socials}</div>
			</>
		);
	} else {
		heading = <h1>MAYA ARMON</h1>;
	}

	let links;
	if (isDesktop) {
		links = (
			<>
				<Link to="reels">Reels</Link>
			</>
		);
	} else {
		links = (
			<>
				<Link className="verticalNavItem" to="reels">
					Reels
				</Link>
				{socials}
			</>
		);
	}

	return (
		<div className="Home pageContent">
			<div className="headingContainer">
				<div className="headingContainer">{heading}</div>
				<div>{links}</div>
			</div>
		</div>
	);
};
