import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "pages/Home";
import Reels from "pages/Reels";
import Resume from "pages/Resume";

export default function setupRoutes() {
	ReactDOM.render(
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="reels" element={<Reels />} />
				<Route path="resume" element={<Resume />} />
			</Routes>
		</Router>,
		document.getElementById("root")
	);
}
