import { useState, useEffect } from "react";

export default (minWidth: number) => {
	const [state, setState] = useState(window.innerWidth > minWidth);

	useEffect(() => {
		const handler = () => {
			setState(window.innerWidth > minWidth);
		};
		window.addEventListener("resize", handler);
		return () => window.removeEventListener("resize", handler);
	});

	return state;
};
