import * as React from "react";
import HeaderWithBack from "components/HeaderWithBack";

export default function Reels() {
	return (
		<div className="Reels pageContent">
			<HeaderWithBack>REELS</HeaderWithBack>

			<div className="videoPlayer">
				<iframe
					src="https://player.vimeo.com/video/593308404?h=db849d48bc&amp;title=0&amp;app_id=122963"
					allow="autoplay; fullscreen; picture-in-picture"
					title="directing showreel"
				></iframe>
			</div>

			<div className="videoPlayer">
				<iframe
					src="https://player.vimeo.com/video/510760267?h=c764169720&amp;title=0&amp;app_id=122963"
					allow="autoplay; fullscreen; picture-in-picture"
					title="Maya Armon - Editing Showreel"
				></iframe>
			</div>
		</div>
	);
}
