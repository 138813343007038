import * as React from "react";
import { Link } from "react-router-dom";

interface IProps {
	linkName?: string;
	link?: string;
	children: string;
}

export default function NavHeader(props: IProps) {
	return (
		<div className="NavHeader">
			<Link to={props.link || "/"}>{props.linkName || "← Home"}</Link>
			<h1>{props.children}</h1>
		</div>
	);
}
