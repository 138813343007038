import * as React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import HeaderWithBack from "components/HeaderWithBack";

export default function Resume() {
	return (
		<div className="Resume pageContent">
			<HeaderWithBack>RESUME</HeaderWithBack>
			<DownloadResume />
			<Document file="/pdf/resume.pdf" loading="Loading Resume...">
				<Page pageNumber={1} height={1400} loading="Loading Resume..." />
			</Document>
		</div>
	);
}

function DownloadResume() {
	return (
		<a className="downloadResume" href="/pdf/resume.pdf" title="Download Resume" download>
			Download
		</a>
	);
}
