import * as React from "react";

export type ViewPort =
	| {
			x: number;
			y: number;
			w: number;
			h: number;
	  }
	| number;

interface IProps {
	glyph: React.VFC<React.SVGProps<SVGSVGElement>>;
	text?: string;
	href: string;
	viewPort: ViewPort;
	className?: string;
}

export default (props: IProps) => {
	const openLink = () => {
		window.open(props.href, "_blank");
	};

	let viewPort =
		typeof props.viewPort === "number"
			? `0 0 ${props.viewPort} ${props.viewPort}`
			: `${props.viewPort.x} ${props.viewPort.y} ${props.viewPort.w} ${props.viewPort.h}`;

	return (
		<div className={`glyphLink ${props.className}`} onClick={openLink}>
			{props.text && <span className="text">{props.text}</span>}
			{React.createElement(props.glyph, { viewBox: viewPort })}
		</div>
	);
};
